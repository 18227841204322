import React from 'react'
import { graphql } from 'gatsby'

// COMPONENTS:
import Intro from 'Components/product/Intro'
import FAQ from 'Components/contact/FAQ'
import Collaboration from 'Components/product/Collaboration'
import Quote from 'Components/product/Quote'
import WebappAndMobile from 'Components/product/WebappAndMobile'
import Statements from 'Components/product/Statements'
import FeatureList from 'Components/product/FeatureList'

interface IProps {
  data: {
    stringsJson: {
      [name: string]: any
    }
  }
}

const ProductPage: React.FC<IProps> = ({
  data: {
    stringsJson: { product },
  },
}) => (
  <>
    <Intro strings={product.intro} lang={product.lang} />
    <Collaboration strings={product.collaboration} />
    <Quote strings={product.quote} />
    <WebappAndMobile strings={product.webAppMobile} />
    <Statements strings={product.statements} />
    <FeatureList strings={product.features} />
    <FAQ faq={product.faq} />
  </>
)

export default ProductPage

export const query = graphql`
  query NoProductQuery {
    stringsJson(product: { lang: { eq: "no" } }) {
      ...Products
    }
  }
`
